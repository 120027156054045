import React from "react"

import CTA_Simple_Link from "./cta_simple_link"
import CTA_Outline from "./cta_outline"
import CTA_Solid from "./cta_solid"

const components:any = {
  "cta_simple_link": CTA_Simple_Link,
  "cta_outline": CTA_Outline,
  "cta_solid": CTA_Solid
}

interface DynamicCTAProps {
  blok: any
}
function DynamicCTA({ blok }:DynamicCTAProps):JSX.Element {
  if (typeof components[blok.component] !== "undefined") {
    const Component = components[blok.component]
    return (<Component blok={blok} key={blok._uid} />)
  }
 
  return  (
    <span>{`CTA [${blok.component}] does not exist.`}</span>
  )
}

export default DynamicCTA