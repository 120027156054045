import { graphql, useStaticQuery } from "gatsby"

interface NavItem {
  _uid: string,
  nav_order: number,
  name: string,
  href: string,
  nestItems: Array<string>,
  sub_items: Array<NavItem>
}
function useNavItems():Array<NavItem> {
  let items:Array<NavItem> = []
  const results = useStaticQuery(graphql`
    query NavigationQuery {
      allStoryblokEntry(filter: {field_component: {in: ["page","Detail Page"]}}) {
        edges {
          node {
            name
            full_slug
            field_component
            uuid
            content
          }
        }
      }
    }
  `)
  results.allStoryblokEntry.edges.forEach((node: any)=>{
    const d = JSON.parse(node.node.content)
    if(d.exclude_in_navigation === true) { return }
    const href = NormalizePageURL(node.node.full_slug)
    const nestItems = href.split('/').filter((item)=>{return item ? true : false})
    items.push({
      _uid: node.node.uuid,
      nav_order: d.nav_order,
      name: d.navigation_label || node.node.name,
      href: href,
      nestItems,
      sub_items: []
    })
  })

  let nav_obj:any = {}

  items.forEach((item)=>{
    let ref = nav_obj
    item.nestItems.forEach((path_part,idx)=>{
      // pointer
      if(!ref[path_part]) {
        ref[path_part] = {}
      }
      ref = ref[path_part]

      if(idx === item.nestItems.length-1) {
        // last item, insert here
        ref.nav_item = item
      }
    })
  })


  // Recursively build nav array from object keys
  let nav_array:Array<NavItem> = NavObjectToNavArray(nav_obj)

  return nav_array
}

function NormalizePageURL (path:string):string {
  let newPath = path
  if(!newPath.startsWith('/')) {
    return `/${newPath}`
  }
  if(!newPath.endsWith('/')) {
    return `${newPath}/`
  }
  return newPath
}

function SortNavItems(a:NavItem,b:NavItem):number {
  if(a.nav_order === b.nav_order) {
    return 0
  }
  if(a.nav_order > b.nav_order) {
    return 1
  }
  return -1
}

function NavObjectToNavArray(navObject:any):Array<NavItem> {
  let navArray:Array<NavItem> = []
  for(let prop in navObject) {
    if(prop != "nav_item") {
      let item = navObject[prop].nav_item
      if(item) {
        item.sub_items = NavObjectToNavArray(navObject[prop])
        navArray.push(item)
      }
    }
  }
  return navArray.sort(SortNavItems)
}

export default useNavItems