import React, { useState } from "react"
import { Link } from "gatsby"

// @ts-ignore
import * as styles from "./Nav.module.css"
import useNavItems from "../../../hooks/useNavItems"
import useSiteSettings from "../../../hooks/useSiteSettings"
import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
import DynamicCTA from "../../cta/DynamicCta"
import StoryblokLink from "../../internal/storyblokLink/StoryblokLink"

function Nav():JSX.Element {
  const [dropdownVisible,setDropdownVisible] = useState<boolean>(false)

  const settings = useSiteSettings()
  const items = useNavItems()

  function toggleDropdown() {
    setDropdownVisible(!dropdownVisible)
  }

  return (
    <>
      <header className={styles.navcolor}>
        <nav>
          <div className={`container ${styles.navContainer}`}>
            <Link className={styles.logo} to="/">
              <StoryblokImage image={settings.logo} width={163} height="auto" />
            </Link>
            <button className={`${styles.dropdownButton} ${dropdownVisible ? "" : styles.collapsed}`} onClick={toggleDropdown}>
              <div className={styles.dropdownIcon}>
                {dropdownVisible ? (
                  <span className={styles.hamburgerCross}>
                    <span></span>
                    <span></span>
                  </span>
                ) : (
                  <span className={styles.hamburger}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                )}
              </div>
            </button>
            <div className={`${styles.navbarNavWrapper} ${dropdownVisible ? styles.navbarVisible : ""}`}>
              <ul className={`menu ${styles.navbarNav}`}>
                {items.map((item)=>{
                  return (
                    <li key={item._uid} className="dropdown submenu nav-item" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <Link to={item.href} className="nav-link">
                        {item.name}
                      </Link>
                      {item.sub_items && item.sub_items.length > 0 ? (
                        <ul role="menu" className={`dropdown-menu ${styles.submenu}`}>
                          {item.sub_items.map((subItem)=>(
                            <li className="nav-item" key={subItem._uid}><Link className="nav-link" to={subItem.href}>{subItem.name}</Link></li>
                          ))}
                      </ul>
                      ) : null}
                    </li>
                  )
                })}
                {settings.page_items ? settings.page_items.map((item)=>{
                  return (
                    <li key={item._uid} className="nav-item dropdown submenu mega_menu mega_menu_two">
                      <StoryblokLink withClass="nav-link" link={item.link} text={item.label} newWindow={item.target === "_blank" ? true : false} />
                    </li>
                  )
                }) : null}
              </ul>
              {settings.nav_items ? settings.nav_items.map((navItem)=>{
                return <DynamicCTA blok={navItem} key={navItem._uid} />
              }) : null}
            </div>
          </div>
        </nav>
      </header>
      <div className={styles.spacer} />
    </>
  )
}

export default Nav