import React from "react"
import StoryblokLink from "../internal/storyblokLink/StoryblokLink"

interface CTA_Outline_Props {
  blok: CTA
}
function CTA_Outline ({ blok }:CTA_Outline_Props):JSX.Element {
  return (
    <StoryblokLink withClass="parc-btn outline" link={blok.link} text={blok.label} />
  )
}

export default CTA_Outline