import React from "react"
import StoryblokLink from "../internal/storyblokLink/StoryblokLink"

interface CTA_Simple_Link_Props {
  blok: CTA
}
function CTA_Simple_Link ({ blok }:CTA_Simple_Link_Props):JSX.Element {
  return (
    <StoryblokLink link={blok.link} text={blok.label} />
  )
}

export default CTA_Simple_Link