import React from "react"

// @ts-ignore
import * as styles from "./Footer.module.css"
import useSiteSettings from "../../../hooks/useSiteSettings"
import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
import { resolveRichText } from "../../../utils/richText"
import StoryblokLink from "../../internal/storyblokLink/StoryblokLink"

function Footer({  }):JSX.Element {
  const settings = useSiteSettings()

  return (
    <footer className={`footer_area h_footer_dark event_footer_area ${styles.footer}`}>
      <div className="container">
        <div className="row">
          {/* About Column */}
          <div className="col-lg-4 col-sm-12 wow fadeInLeft" data-wow-delay="0.2s">
            <div className="f_widget dark_widget" data-wow-delay="0.2s">
              <a href="/" className={`f-logo ${styles.footer_logo}`}>
                {settings.logo_footer ? <StoryblokImage image={settings.logo_footer} /> : null}
              </a>
              <div className="basic-rich-text-fix" dangerouslySetInnerHTML={resolveRichText(settings.content_left)} />
              <StoryblokLink link={settings.copyright_link}>
                © {settings.copyright}
              </StoryblokLink>
              <div className={`f_social_icon ${styles.footer_socials}`}>
                {settings.facebook_url ? (
                  <a href={settings.facebook_url} target="_blank" >
                    <i className="ti-facebook"></i>
                  </a>
                ) : null}
                {settings.linkedin_url ? ( 
                  <a href={settings.linkedin_url} target="_blank" >
                    <i className="ti-linkedin"></i>
                  </a>
                ) : null}
                {settings.instagram_url ? ( 
                  <a href={settings.instagram_url} target="_blank" >
                    <i className="ti-instagram"></i>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          {/* Center Column */}
          <div className="col-lg-4 col-sm-6 wow fadeInLeft" data-wow-delay="0.2s">
            <div className="f_widget dark_widget about-widget pl_70 basic-rich-text-fix" dangerouslySetInnerHTML={resolveRichText(settings.content_center)} />
          </div>
          {/* Right Column */}
          <div className="col-lg-4 col-sm-6 wow fadeInRight" data-wow-delay="0.2s">
            <div className="f_widget dark_widget basic-rich-text-fix" dangerouslySetInnerHTML={resolveRichText(settings.content_right)} />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
