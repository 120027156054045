// extracted by mini-css-extract-plugin
export var navcolor = "Nav-module--navcolor--HAHcB";
export var logo = "Nav-module--logo--zufNx";
export var navContainer = "Nav-module--navContainer--bKug2";
export var navbarNavWrapper = "Nav-module--navbarNavWrapper--EJ7hZ";
export var navbarNav = "Nav-module--navbarNav--gLRsg";
export var spacer = "Nav-module--spacer--7ok2r";
export var dropdownButton = "Nav-module--dropdownButton--WfTaG";
export var dropdownIcon = "Nav-module--dropdownIcon--beL4T";
export var hamburger = "Nav-module--hamburger--awd0r";
export var hamburgerCross = "Nav-module--hamburgerCross--lHRky";
export var collapsed = "Nav-module--collapsed--hkCuk";
export var navbarVisible = "Nav-module--navbarVisible--hWv0Q";
export var submenu = "Nav-module--submenu--iFV1V";