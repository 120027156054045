import React from "react"

interface StoryblokImageProps {
  image: StoryblokRef.Image,
  width?: number | string,
  height?: number | string,
  sizes?: Breakpoint,
  url?: string
}

interface Breakpoint{
  [key:string]: string
}

interface url{
  image?:string
  url:string
}

function getImageProps(image:string) {
  let url = image;
  const validImageUrlPattern =
    /^(https?:)?\/\/a.storyblok.com\/f\/*[0-9]+\/*[0-9]*x*[0-9]*\/[A-Za-z0-9]+\/[\S]+\.[a-zA-Z]+/;
  const storyblokRegex = /^(https?:)?\/\/a.storyblok.com\//;
  url = validImageUrlPattern.test(url!) ? url : "";

  if (!url) {
    return false;
  }

  let originalPath = url.replace(storyblokRegex, "");

  let [, , dimensions, , filename] = originalPath.split("/");
  let [width, height] = dimensions.split("x").map((num) => parseInt(num, 10));
  let [, extension] = filename.split(".");

  let aspectRatio = width / height;
  let metadata = { dimensions: { width, height, aspectRatio } };

  return {
    originalPath,
    extension,
    metadata,
  };
}

function buildSrcSet({sizes, image, url, width, height}:StoryblokImageProps):JSX.Element {
  let props = getImageProps(url!);
  let deafultWidth = width ? width : props? props.metadata.dimensions.width : "100%";
  let defaultHeight = height ? height : props ? props.metadata.dimensions.height : "auto";
  if (sizes) {
    // if (image.focus != "") {
      return (
      <picture>
        {Object.keys(sizes).map((breakpoint) => {
          var widthMinMax = "max-width";
          var width = sizes[breakpoint].split("x")[0];
          var height = sizes[breakpoint].split("x")[1];
          
          if(breakpoint.includes("-")){
            var minMax = breakpoint.split("-");
            var min = minMax[0];
            var max = minMax[1];
            if (min && max) {
              return (
                <source
                  media={`(min-width: ${min}px) and (max-width:${max}px)`}
                  srcSet={`${image.filename}/m/${width}x${height}`}
                  key={breakpoint + ":" + sizes[breakpoint]}
                />
              );
            }
            if (min && !max) {
              return (
                <source
                  media={`(min-width: ${min}px)`}
                  srcSet={`${image.filename}/m/${width}x${height}`}
                  key={breakpoint + ":" + sizes[breakpoint]}
                />
              );
            }
            if (!min && max) {
              return (
                <source
                  media={`(max-width:${max}px)`}
                  srcSet={`${image.filename}/m/${width}x${height}`}
                  key={breakpoint + ":" + sizes[breakpoint]}
                />
              );
            }
          }
        })}
         <img src={url} style={{ maxWidth: "100%", height: "auto" }} width={deafultWidth} height={defaultHeight} loading="lazy" />
      </picture>
      )
    // }
  }
  return (
     <img src={url} alt={image.alt} width={deafultWidth} height={defaultHeight} loading="lazy"/>
  );
}

function StoryblokImage({ image, width, height, sizes }:StoryblokImageProps):JSX.Element {
  if(!image) {
    return (<span>INVALID STORYBLOK IMAGE</span>)
  }
  let url = normalizeURL(image.filename)

  return buildSrcSet({sizes, image, url, width, height})
  
  // return ( <img src={url} alt={image.alt} width={width} height={height} /> )
}

function normalizeURL(url:string):string {
  // SB Image can't handle svg images.
  if(!url){return ""}
  if(url.endsWith('.svg')) {
    return url
  }
  let current = url+'/m'
  
  // If no other changes to the url atleast add server-side webp detection
  if(current.endsWith('/m')) {
    current += '/'
  }
  return current
}

export { normalizeURL }
export default StoryblokImage