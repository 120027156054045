import { graphql, useStaticQuery } from "gatsby"
import { Richtext } from "storyblok-js-client"

interface SiteSettings {
  event_date: Date,
  logo: StoryblokRef.Image,
  nav_items: Array<any>,
  page_items: Array<any>,

  logo_footer: StoryblokRef.Image,
  content_left: Richtext,
  linkedin_url: string,
  facebook_url: string,
  instagram_url: string,
  copyright: string,
  copyright_link: StoryblokRef.Link,
  content_center: Richtext,
  content_right: Richtext,

  from_email_address: string,
  internal_emails_send_to: string,
  abstract_emails_send_to: string,

  onsite_registration_confirmation_email: string,
  onsite_registration_confirmation_email_wri: string
  online_registration_confirmation_email: string,
  online_registration_confirmation_email_wri: string,

  abstract_confirmation_email: string,
  abstract_confirmation_email_wri: string,

  sponsor_confirmation_email: string,
  sponsor_confirmation_email_wri: string,

  exhibitor_confirmation_email: string,
  exhibitor_confirmation_email_wri: string,

  alacarte_confirmation_email: string,
  alacarte_confirmation_email_wri: string,

  reports_password: string
}
function useSiteSettings():SiteSettings {
  const results = useStaticQuery(
    graphql`
      query GetSiteSettings {
        storyblokEntry(
          field_component: {eq: "Settings"}
        ) {
          uuid
          name
          field_component
          content
        }
      }
    `
  )
  const data = JSON.parse(results.storyblokEntry.content)
  let settings:SiteSettings = {
    event_date: new Date(data.event_date),
    logo: data.logo,
    page_items: data.page_items,
    nav_items: data.nav_items,

    logo_footer: data.logo_footer,
    content_left: data.content_left,
    linkedin_url: data.linkedin_url,
    facebook_url: data.facebook_url,
    instagram_url: data.instagram_url,

    copyright: data.copyright,
    copyright_link: data.copyright_link,
    content_center: data.content_center,
    content_right: data.content_right,

    from_email_address: data.from_email_address,
    internal_emails_send_to: data.internal_emails_send_to,
    abstract_emails_send_to: data.abstract_emails_send_to,

    onsite_registration_confirmation_email: data.onsite_registration_confirmation_email,
    onsite_registration_confirmation_email_wri: data.onsite_registration_confirmation_email_wri,
    online_registration_confirmation_email: data.online_registration_confirmation_email,
    online_registration_confirmation_email_wri: data.online_registration_confirmation_email_wri,

    abstract_confirmation_email: data.abstract_confirmation_email,
    abstract_confirmation_email_wri: data.abstract_confirmation_email_wri,

    sponsor_confirmation_email: data.sponsor_confirmation_email,
    sponsor_confirmation_email_wri: data.sponsor_confirmation_email_wri,

    exhibitor_confirmation_email: data.exhibitor_confirmation_email,
    exhibitor_confirmation_email_wri: data.exhibitor_confirmation_email_wri,

    alacarte_confirmation_email: data.alacarte_confirmation_email,
    alacarte_confirmation_email_wri: data.alacarte_confirmation_email_wri,

    reports_password: data.reports_password
  }
  return settings
}

export { SiteSettings }
export default useSiteSettings