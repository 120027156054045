import { useEffect, useState } from "react"
import StoryblokClient from "storyblok-js-client"


interface StoryblokApiConfigOptions {
  version: string,
  resolve_relations?: Array<string>,
  from_release?: string | null
}

const Storyblok = new StoryblokClient({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
  cache: {
    clear: "auto",
    type: "memory",
  },
})
 
function useStoryblok(originalStory:any, location:any):StoryblokRef.Story {
    let [story, setStory] = useState(originalStory)
 
    if(story && typeof story.content === "string") {
      story.content = JSON.parse(story.content)
    }
    
    // see https://www.storyblok.com/docs/Guides/storyblok-latest-js
    function initEventListeners() {
      // const isBrowser = typeof window !== "undefined"
      const { StoryblokBridge } = window
 
      if (typeof StoryblokBridge !== "undefined") {
        const storyblokInstance = new StoryblokBridge({
            // resolve_relations: [] // Make sure to update gatsbyconfig AND draft api call
        })
 
        storyblokInstance.on(["change", "published"], (event:any) => {
          // reload project on save and publish
          console.log('change, time to reload')
          window.location.reload()
        })
    
        storyblokInstance.on(["input"], (event:any) => {
          // live updates when editing
          if(event.story) {
            setStory(event.story)
          }
        })
 
        storyblokInstance.on(["enterEditmode"], (event:any) => {
          let config_options:StoryblokApiConfigOptions = {
            version: "draft",
            // resolve_relations: [] // Make sure to update gatsbyconfig AND instance init
          }

          // let urlParams = new URLSearchParams(location.search)
          // if(urlParams.has("_storyblok_release")) {
          //   config_options.from_release = urlParams.get("_storyblok_release")
          //   console.log(`Using release [${config_options.from_release}]`)
          // }
          // loading the draft version on initial view of the page
          Storyblok
            .get(`cdn/stories/${event.storyId}`, config_options)
            .then(({ data }) => {
              console.log('load draft on enter edit mode: ', data)
              if(data.story) {
                setStory(data.story)
              }
            })
            .catch((error) => {
              console.error(error);
            }) 
        }) 
      }
    }
 
    function addBridge(callback:any) {
        // check if the script is already present
        const existingScript = document.getElementById("storyblokBridge")
        if (!existingScript) {
          const script = document.createElement("script")
          script.src = "//app.storyblok.com/f/storyblok-v2-latest.js"
          script.id = "storyblokBridge"
          document.body.appendChild(script)
          script.onload = () => {
            // call a function once the bridge is loaded
            callback()
          }
        } else {
          callback()
        }
    }
 
    useEffect(() => {
      // load bridge only inside the storyblok editor
      if(location && location.search && location.search.includes("_storyblok")) {
        // first load the bridge and then attach the events
        addBridge(initEventListeners)
      }
    }, []) // it's important to run the effect only once to avoid multiple event attachment
 
    return story
}

export { Storyblok }
export default useStoryblok