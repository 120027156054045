import React from "react"

import "../../../assets/themify-icon/themify-icons.css"
import "../../../assets/simple-line-icon/simple-line-icons.css"
import "../../../assets/font-awesome/css/all.css"
import "../../../assets/elagent/style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../../assets/animate.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../../assets/main.css"
import "../../../assets/responsive.css"
import "../../../assets/parc.css"

import Nav from "../nav/Nav"
import Footer from "../footer/Footer"


interface GlobalLayoutProps {
  children: any
}
function GlobalLayout({children}:GlobalLayoutProps) {
  return (
    <>
      <Nav />
      <main className="body_wrapper">
        {children}
      </main>
      <Footer />
    </>
  )
}

function BasicGlobalLayout({children}:GlobalLayoutProps) {
  return (
    <>
      <main className="body_wrapper">
        {children}
      </main>
    </>
  )
}

export { BasicGlobalLayout }
export default GlobalLayout