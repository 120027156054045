import { Richtext } from "storyblok-js-client"

import { Storyblok } from "../lib/storyblok"

export function resolveRichText(sourceRichTextData?:Richtext):RichTextObject {
  if(sourceRichTextData) {
    return { __html: Storyblok.richTextResolver.render(sourceRichTextData) };
  } else {
    return { __html: "" };
  }
}